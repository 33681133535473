@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.mainContainer {
  @include cursor('white', default);
  @include primaryScreen();
  @include flex();

  position: relative;
  height: fit-content !important;
  padding: 30px;
  background: linear-gradient(#f9cf43, #f4bc3e);
}

.heading {
  @include screenHeading();
}

.categoriesContainer {
  @include flex();

  flex-wrap: wrap;
  align-items: flex-start;
  width: 90vw;
  min-height: 90vh;
  padding: 100px 0;

  .category {
    @include flex(center, initial);
    @include cursor('default', pointer);

    position: relative;
    width: 20vw !important;
    min-width: 200px;
    max-width: 250px;
    height: 20vw !important;
    min-height: 200px;
    max-height: 250px;
    margin: 20px !important;
    border-radius: 20px;
    background: #fff;
    box-shadow: 15px 15px 0 0 rgba(0, 0, 0, 0.2);
    transition: all 0.2s;

    &:hover {
      border-radius: 15px;
      transform: scale(1.03);
    }

    img {
      height: 80%;
    }

    p {
      position: absolute;
      bottom: 0;
      color: #6d6d6d;
      font-size: 20px;
      font-family: $secondary-font;
      letter-spacing: 1px;
    }
  }
}

.whatsDifferentIcon {
  border-radius: 10px;
  transform: scale(0.8);
}

@media (max-width: 768px) {
  .mainContainer {
    padding: 30px 10px;
  }

  .categoriesContainer {
    width: 100vw;
    padding: 70px 0;

    .category {
      width: 40vw !important;
      min-width: 40px;
      height: 40vw !important;
      min-height: 40px;
      margin: 10px !important;
      border-radius: 15px;
      box-shadow: 10px 10px 0 0 rgba(0, 0, 0, 0.2);

      &:hover {
        border-radius: 10px;
        transform: scale(1.01);
      }

      p {
        font-size: 18px;
        letter-spacing: 0;
      }
    }
  }
}
