@import '../../styles/mixins.scss';

.loadingContainer {
  @include flex();

  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.06);
  pointer-events: none !important;

  div {
    width: 300px;
    max-width: 40vw;
    height: 300px;
    max-height: 40vw;
  }
}
