@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.mainContainer {
  @include shopMainContainer();

  font-weight: 500 !important;
}

.mainHeading {
  @include shopMainHeading();

  margin-bottom: 70px;
}

.paymentMethodInfo {
  width: 400px;
  max-width: 85vw;
  margin: 10px 0 0;
  color: #555;
  font-size: 14px;
  text-align: left;
}

.sectionHeading {
  width: 100%;
  margin-bottom: 0 !important;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  text-align: left;
}

.orderInfoContainer {
  @include flex(space-between, flex-start);

  width: 75vw;
}

.line {
  width: 100%;
  margin: 0;
  margin-bottom: 10px;
  color: #999;
}

.cartItemsContainer {
  width: 40vw;
  padding-left: 50px;

  .cartItem {
    @include flex(space-between, center);

    width: 100%;
    padding: 20px 0;

    .itemQuantity {
      margin-left: 5px;
      color: #999;
      font-size: 12px;
    }

    .itemTitle {
      overflow: hidden;
      max-width: 150px;
      padding-right: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .productInfo,
    .itemTitle,
    .itemQuantity,
    .price {
      margin: 0 !important;
      font-size: 14px;
    }

    img {
      object-fit: cover;
      width: 50px;
      height: 50px;
      border-radius: 4px;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05);
      object-position: center;
    }
  }

  .productTextContainer {
    margin-left: 20px;
    text-align: left;

    .productInfo {
      display: flex;
      align-items: center;
      margin: 0;
      letter-spacing: 1px;
    }

    .salePrice {
      color: #cf9410 !important;
      font-weight: 500;

      span {
        color: #888 !important;
        font-weight: 400;
        text-decoration: line-through;
      }
    }

    .price,
    .salePrice {
      margin: 0;
      color: #888;
      font-size: 12px;
    }
  }
}

.cartLink {
  @include capsLink();
}

.inputsContainer {
  @include flex(flex-start, flex-start, column);
}

.name,
.email,
.phone,
.address,
.paymentMethodContainer,
.paymentMethodHeading,
.message {
  width: 380px;
  margin-top: 20px;
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px;
  background: #fff;
  outline: 0;
}

.paymentMethodHeading {
  margin-top: 15px;
  margin-bottom: 0 !important;
  padding: 0 !important;
  border: none !important;
  background: none;
  font-weight: 500;
  text-align: left;
}

.accountDetails {
  display: flex;
  width: fit-content !important;
  margin-bottom: 0 !important;

  .accountInfo {
    margin-bottom: 5px;
    font-weight: 600;

    &:first-child {
      width: 130px !important;
      margin-right: 20px;
      font-weight: 400;
      text-align: left;
    }
  }
}

.paymentMethodContainer {
  @include flex(space-between);

  height: 80px;
  margin-top: 5px;
  padding: 0;
  border: none !important;

  div {
    @include flex();

    width: 33%;
    height: 100%;
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    transition: all 0.3s;
  }

  .selectedMethod,
  div:hover {
    border: 1px solid rgba(0, 0, 0, 0) !important;
    background-color: $primary-color;
    color: #fff;
  }
}

.chargesContainer {
  @include flex(flex-end, flex-end, column);

  font-size: 14px;
  text-align: right;

  p {
    margin-bottom: 5px !important;
  }

  .line {
    width: 200px;
    margin: 5px 0;
  }

  p:nth-child(1) {
    margin-bottom: 5px;
  }

  .chargesPrice {
    width: 80px;
  }

  .totalPrice {
    color: #000;
    font-weight: 500;
    font-size: 16px;
  }
}

.message,
.address {
  overflow-y: auto;
  height: 100px;
  resize: none;
}

::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.confirmBtn {
  @include cursor('default', pointer);

  align-self: center;
  margin: 10px 0 50px;
  padding: 7px 30px;
  border: none !important;
  border-radius: 15px;
  background-color: rgb(222, 131, 131);
  color: #fff;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1);
  outline: none !important;

  &:hover {
    transform: scale(1.04);
  }
}

.errorShown {
  width: 100%;
  margin-top: 20px;
  color: rgb(222, 131, 131);
  text-align: center;
  visibility: visible;
}

.errorHidden {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  visibility: hidden;
}

.infoText {
  width: 50vw;
  margin: 40px 0;
}

@media (max-width: 992px) {
  .name,
  .email,
  .phone,
  .address,
  .message,
  .paymentMethodHeading,
  .paymentMethodContainer {
    width: 340px;
  }

  .cartItemsContainer {
    width: 40vw;
    padding-left: 30px;

    .cartItem {
      padding: 10px 0;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  .orderInfoContainer {
    width: 90vw;
  }
}

@media (max-width: 768px) {
  .orderInfoContainer {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .mainContainer {
    padding: 40px 20px !important;
  }

  .name,
  .email,
  .phone,
  .address,
  .message,
  .paymentMethodHeading,
  .paymentMethodContainer {
    width: calc(100vw - 40px);
  }

  .cartItemsContainer {
    width: calc(100vw - 40px);
    margin-bottom: 50px;
    padding-left: 0;
  }

  .sectionHeading {
    text-align: center;
  }

  .mainHeading {
    @include shopMainHeading();

    margin-bottom: 30px;
  }

  .accountDetails {
    font-size: 14px;

    .accountInfo {
      &:first-child {
        width: 120px !important;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 480px) {
  .paymentMethodContainer {
    height: 70px;

    div {
      font-size: 13px;
    }
  }

  .cartItemsContainer .cartItem {
    @include flex(space-between, flex-start);

    p {
      margin: 0 !important;
      font-size: 15px;
    }

    img {
      width: 45px;
      height: 45px;
    }

    .productTextContainer {
      margin-left: 15px;
    }
  }

  .itemTitle {
    max-width: 100px;
  }

  .chargesContainer {
    font-size: 15px;
  }
}
