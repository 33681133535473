@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.servicesSectionContainer {
  @include flex(center, center, column);

  width: 100%;
  height: fit-content;
  min-height: 400px;
  margin: 40px 0 0 0;
  background: #fff5e0;
  padding-bottom: 50px;
  padding-top: 20px;

  h2 {
    margin: 40px 10px 50px 10px;
    color: $primary-color;
  }
}

.contentContainer {
  max-width: 1200px;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.serviceCard {
  padding: 20px;

  div {
    border-radius: 30px;
    text-align: center;
    position: relative;
    padding: 0px 10px 20px 20px;
    color: #222;
    height: 300px;
    max-width: 350px;
    margin: auto;
    margin-bottom: 30px;

    h4 {
      margin-bottom: 10px;
      font-weight: 600 !important;
    }

    p {
      margin-bottom: 30px;
      font-weight: 500 !important;
    }

    img {
      height: 130px;
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 992px) {
  .contentContainer {
  }
}

@media (max-width: 576px) {
  .contentContainer {
  }
}
