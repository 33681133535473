@import '../../../../styles/mixins.scss';
@import '../../../../styles/variables.scss';

.mainContainer {
  @include gameMainContainer();

  height: 100vh;
  min-height: 800px;
}

.heading {
  @include screenHeading();
}

.scoreText {
  @include gameScoreText();
}

.cardsContainer {
  position: relative;
  display: flex;

  div {
    width: 335px;
    height: 550px;
  }
}

.differencesContainer {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 !important;

  p {
    position: absolute;
    width: 65px;
    height: 65px;
    border: 2px solid #fff;
    border-radius: 50%;
    background: none !important;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
}

.clickedCircle {
  opacity: 1 !important;
}

.gameCard {
  position: relative;
  overflow: hidden;
  margin: 30px 40px 0;
  border-radius: 20px;
  background: transparent;
  box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.1);

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center;
  }
}

@media (max-width: 992px) {
  .heading {
    margin-top: 10px;
    font-size: 25px;
  }

  .cardsContainer div {
    width: 305px;
    height: 500px;
  }
}

@media (max-width: 768px) {
  .mainContainer {
    justify-content: flex-start !important;
  }

  .scoreText {
    position: initial;
    margin-top: 120px;
  }

  .heading {
    font-size: 20px;
  }

  .cardsContainer div {
    width: 245px;
    height: 400px;
    margin: 50px 20px 0;
  }

  .gameCard {
    margin: 50px 20px;
  }
}

@media (max-width: 576px) {
  .heading {
    margin-top: 5px;
  }

  .scoreText {
    margin-top: 90px;
  }

  .cardsContainer div {
    width: 185px;
    height: 300px;
    margin: 80px 20px 0;
  }

  .differencesContainer p {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 480px) {
  .cardsContainer div {
    width: 160px;
    height: 250px;
    margin: 80px 10px;
  }

  .differencesContainer p {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 360px) {
  .cardsContainer div {
    width: 125px;
    height: 200px;
    margin: 80px 8px;
  }
}
