@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.mainContainer {
	@include cursor('white', default);

	position: relative;
	z-index: 2;
	width: 100vw;
	padding: 50px 40px 0;
	background: $primary-color;
	color: #fff;
	box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.03);

	h4 {
		margin: 0;
		font-family: Raleway, sans-serif;
		line-height: 28px;
		letter-spacing: 4px;
	}

	a {
		@include cursor();

		color: #fff;
		text-decoration: none;
	}

	span {
		padding: 0 10px;
		color: rgba(255, 255, 255, 0.5);
	}

	p {
		font-size: 14px;
	}
}

.contentContainer {
	@include flex(space-between, center);
}

.linksContainer {
	@include flex();

	a {
		@include flex();
		@include cursor();

		width: 35px;
		height: 35px;
		margin-right: 14px;
		border: none;
		border-radius: 50%;
		background: #fff;
		box-shadow: none;
		outline: none;

		img {
			width: 65%;
		}
	}
}

@media (max-width: 992px) {
	.contentContainer {
		@include flex(center, center, column);

		text-align: center;
	}

	.linksContainer {
		margin-top: 30px;

		a {
			margin: 0 10px;
		}
	}
}

@media (max-width: 576px) {
	.mainContainer {
		span {
			display: none;
		}

		a {
			margin-bottom: 6px;
			font-size: 14px;
		}

		.pagesContainer {
			@include flex(center, center, column);

			margin-top: 20px;
		}
	}
}
