@import '../../styles/mixins.scss';

.back {
  @include cursor('default', pointer);
  @include square(40px);

  position: absolute;
  top: 30px;
  left: 40px;
  transition: all 0.3s;
}

.back:hover {
  transform: scale(1.2);
}

.threeCircles {
  position: absolute;
  width: 14vw;

  &.top {
    top: 20px;
  }

  &.right {
    right: 40px;
  }

  &.left {
    left: 40px;
  }

  &.bottom {
    bottom: 20px;
  }
}

.sprinkles {
  width: 100vw;
  height: 15vw;
}

.puzzles {
  position: absolute;
  width: 20vw;

  &.top {
    top: 20px;
  }

  &.right {
    right: 20px;
  }

  &.left {
    left: 20px;
  }

  &.bottom {
    bottom: 20px;
  }
}

.home {
  @include cursor('default', pointer);
  @include square(50px);

  position: absolute;
  top: 20px;
  left: 30px;
  transition: all 0.3s;
}

.home:hover {
  transform: scale(1.2);
}

@media (max-width: 768px) {
  .home {
    top: 15px;
    left: 25px;
  }
}

@media (max-width: 576px) {
  .home {
    @include square(40px);

    top: 20px;
    left: 30px;
  }
}

@media (max-width: 480px) {
  .home {
    @include square(30px);

    top: 28px;
    left: 25px;
  }
}

.dashboardButton {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform-origin: center center;
  stroke-opacity: 0.5;

  text {
    font-weight: 900;
    font-size: 55px;
    letter-spacing: 40px;
    fill: #fff;
  }

  tspan {
    font-size: 100px;
    letter-spacing: 50px;
  }
}

@media (max-width: 768px) {
  .back {
    top: 25px;
    left: 30px;
  }

  .home {
    top: 15px;
    left: 25px;
  }
}

@media (max-width: 576px) {
  .back {
    @include square(30px);

    top: 30px;
    left: 30px;
  }

  .home {
    @include square(40px);

    top: 20px;
    left: 30px;
  }
}
