@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.shopContainer {
  @include shopMainContainer();
}

.cartLink {
  @include capsLink('right');
}

.mobileFiltersContainer {
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 20px 0 40px;

  p {
    color: #777;
    font-size: 12px;
  }

  select {
    width: 200px;
    height: 35px;
    margin: 0 10px 10px 0;
    padding: 5px 15px;
    border: none !important;
    border-radius: 10px;
    background: $primary-color;
    color: #fff;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05) !important;
    outline: none !important;
    font-weight: 600;
    text-align: center;
    appearance: none;
  }
}

.desktopFiltersContainer {
  @include flex(flex-start);

  flex-wrap: wrap;
  width: 1100px;
  padding: 40px 20px;

  button {
    @include cursor('default', pointer);

    margin: 0 10px 10px 0;
    padding: 5px 30px;
    border: none !important;
    border-radius: 20px;
    background: #efefef;
    box-shadow: none !important;
    outline: none !important;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.04);
    }
  }

  .activeButton {
    background: $primary-color;
    color: #fff;

    &:hover {
      transform: scale(1);
    }
  }
}

.mainHeading {
  @include shopMainHeading();
}

.productsContainer {
  @include flex(flex-start);

  width: 1100px;
}

.product {
  @include flex(center, center, column);
  @include square(255px);
  @include cursor();

  position: relative;
  overflow: hidden;
  margin: 0 10px 50px;
  border-radius: 10px;
  text-decoration: none !important;

  img {
    border-radius: 10px;
  }

  &:hover {
    img {
      transform: scale(1.01);
    }
  }

  .saleTag {
    position: absolute;
    top: 0;
    left: 20px;
    z-index: 5;
    padding: 3px 5px;
    background-color: $primary-color;
    color: #fff;
    font-size: 12px;
    letter-spacing: 2px;
  }

  .productTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 20%;
  }

  .salePrice {
    color: rgb(222, 168, 6);
    font-size: 14px;

    span {
      margin-left: 5px;
      color: #aaa;
      text-decoration: line-through;
    }
  }

  .price {
    font-size: 14px;
    color: #666;
  }

  p {
    overflow: hidden;
    width: 100%;
    margin: 0 5px;
    color: #444;
    font-weight: 600 !important;
    font-size: 15px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .productIconContainer {
    @include square(100%);

    position: relative;
    height: 80%;
    margin-bottom: 5px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.03);
    pointer-events: none;
  }

  .productIcon {
    @include square(100%);

    object-fit: cover;
    transition: all 0.3s;
    object-position: center;
  }
}

@media (max-width: 1200px) {
  .productsContainer,
  .desktopFiltersContainer {
    width: 900px;
  }

  .desktopFiltersContainer button {
    transform: scale(0.9);
  }

  .product {
    @include square(205px);
  }
}

@media (max-width: 992px) {
  .productsContainer,
  .desktopFiltersContainer {
    width: 780px;
  }

  .product {
    @include square(175px);
  }
}

@media (max-width: 786px) {
  .productsContainer {
    width: 570px;
  }

  .product {
    @include square(170px);
  }
}

@media (max-width: 576px) {
  .productsContainer {
    width: 420px;
  }

  .product {
    @include square(170px);

    margin: 0 20px 50px;

    p {
      font-size: 12px;
    }

    .salePrice,
    .price {
      font-size: 10px;
    }

    .saleTag {
      font-size: 10px;
    }
  }
}

@media (max-width: 480px) {
  .productsContainer {
    width: 98vw;
  }

  .product {
    @include square(calc(49vw));

    margin: 0 0 30px;
  }
}
