@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.mainContainer {
	padding: 80px 30px;

	h2 {
		margin: 0 20px 10px;
		color: $primary-color;
	}
}

@media (max-width: 576px) {
	.mainContainer h2 {
		font-size: 18px;
	}
}
