@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.mainContainer {
	overflow-x: scroll;
	overflow-y: hidden;
	width: 100vw;
	padding: 30px 100px;
	background-color: $primary-color;

	&::-webkit-scrollbar {
		display: none !important;
	}

	h1 {
		width: fit-content;
		padding: 0 200px 0 100px;
		color: #fff;
		font-weight: normal;
		font-size: 190px;
		font-family: 'Crafty Girls', cursive;
		letter-spacing: 5px;
		white-space: nowrap;
		pointer-events: none !important;
	}
}
