@import './styles/mixins.scss';
@import './styles/variables.scss';
@import '~bootstrap/scss/bootstrap';

body {
  @include cursor();

  overflow-x: hidden;
  font-family: $primary-font;
}

.carousel-control-prev,
.carousel-control-next,
.swiper-button-next {
  text-decoration: none !important;
  opacity: 0.7 !important;
  transform: scale(1) !important;

  &:hover {
    transform: scale(1.1) !important;
  }
}
