@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

button:hover {
  @include cursor('default', default);
}

.contactUsContainer {
  @include cursor('default', default);
  @include flex();

  overflow: hidden;
  width: 100vw;
  min-height: 100vh;
  padding: 50px;
  background-image: url('../../assets/images/music-icons.png');
  background-position: center;
  background-size: contain;
  text-align: center;
}

.chuchu {
  position: fixed;
  top: 30%;
  right: -110px;
  transform: rotate(-45deg);
}

.inputsContainer {
  @include flex(center, initial, column);
}

.name,
.email,
.message {
  width: 380px;
  margin-top: 20px;
  padding: 10px 20px;
  border: 0;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.12);
  outline: 0;
  appearance: none;
}

.message {
  overflow-y: auto;
  height: 200px;
  resize: none;
}

::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.helloText {
  color: #727272;
  font-size: 48px;
}

.sendBtn,
.sendBtn:focus,
.sendBtn:active {
  @include cursor('default', pointer);

  width: 85px;
  height: 50px;
  margin-top: 0;
  padding: 0;
  border: none;
  border-radius: 10px;
  background-color: transparent !important;
  background-image: url('../../assets/images/mail-icon.png');
  background-position: center;
  background-size: cover;
  outline: none !important;
  transition: transform 0.1s;
  transform: scale(1);
}

.sendBtn:hover {
  transform: scale(1.08);
}

.successMessageContainer {
  @include cursor('white', default);

  position: fixed;
  bottom: -45vw;
  left: 50%;
  z-index: 999999999;
  width: 90vw;
  height: 90vw;
  margin-top: -45vw;
  margin-left: -45vw;
  border-radius: 50%;
  background: #f7c944;
  transform: scale(0);
}

.successMessage {
  position: absolute;
  top: 25%;
  right: 0;
  left: 0;
  width: 70%;
  margin: auto;
  color: #fff;
  font-size: 40px;
}

.confetti {
  margin-top: -20% !important;
}

.errorShown {
  margin-top: 20px;
  color: #f7c944;
  visibility: visible;
}

.errorHidden {
  margin-top: 20px;
  color: #f7c944;
  visibility: hidden;
}

@media (max-width: 768px) {
  .chuchu {
    right: -120px;
    transform: scale(0.7) rotate(-45deg);
  }

  .name,
  .email,
  .message {
    width: 60vw;
  }

  .successMessage {
    font-size: 30px;
  }
}

@media (max-width: 576px) {
  .helloText {
    font-size: 30px;
  }

  .chuchu {
    right: -150px;
    transform: scale(0.6) rotate(-45deg);
  }

  .successMessage {
    font-size: 20px;
  }
}
