@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.mainScreen {
  @include flex(center, center, column);
  @include primaryScreen();

  position: relative;
  overflow: hidden !important;
}

.yellow {
  color: $primary-color;
}

.landingSprinkles {
  position: absolute;
  top: 0;
  height: 20%;
}

.mainContainer {
  @include cursor('default', auto);
  @include flex();

  width: 100vw;
  height: 70%;
}

.introText,
.sayHi,
.beakClickText,
.registerLink,
.selectCategory {
  font-weight: 400;
}

.chirpSounds {
  height: 0 !important;
  visibility: hidden !important;
}

.sayHi,
.registerLink {
  @include cursor('default', pointer);

  position: absolute;
  top: 20px;
  right: 20px;
  color: #464646;
  font-size: 20px;
  text-decoration: none;
  transition: color 0.1s;
  font-weight: 500;
}

.registerLink {
  right: unset;
  left: 20px;
}

.sayHi:hover,
.registerLink:hover {
  color: $primary-color !important;
  text-decoration: none;
}

.intro {
  @include flex(center, center, column);

  height: 100%;
  padding-left: 20px;
}

.beakClickText {
  position: absolute;
  bottom: 0;
  width: 100vw;
  padding: 0 10px;
  text-align: center;
}

.links {
  @include flex(center, flex-end, column);

  right: 30px;
  height: 100%;

  text-align: right;
}

.mutedText {
  margin-bottom: 30px;
  color: #999;
  font-size: 14px;
}

.linksList {
  float: right;
  max-width: max-content;
  text-align: right;
}

.listItem,
.listLinkItem {
  @include cursor('default', pointer);

  height: 30px;
  margin-bottom: 20px;
  color: inherit;
  list-style: none;
  font-weight: 500;
  color: #555;
  font-size: 20px;
  text-decoration: none;
  transition: all 0.1s;
}

.listLinkItem:hover {
  color: #fabe37;
  font-weight: bolder;
  font-size: 25px;
  text-decoration: none;
}

.chuchu {
  width: 30vw;
}

@media (max-width: 768px) {
  .mainContainer {
    & div {
      width: 100vw;
      max-width: 100%;
      height: fit-content;
      padding: 30px 0;
    }

    .listLinkItem {
      font-size: 16px;
    }

    .introText {
      font-size: 22px;
    }

    .links {
      right: 0;
      align-items: center;
      margin-bottom: 40px;
      text-align: center;

      .linksList {
        padding: 0;
        text-align: center;

        .listItem {
          width: 100vw;
          margin-bottom: 10px;

          .listLinkItem:hover {
            font-size: 18px;
          }
        }
      }
    }
  }

  .beakClickText,
  .sayHi,
  .registerLink {
    font-size: 14px;
  }
}
