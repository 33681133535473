@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.formContainer {
  @include flex(center, center, column);
}

.input {
  width: 380px;
  max-width: 80vw;
  margin-top: 20px;
  padding: 10px 20px;
  border: 0;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.12);
  outline: 0;
  resize: none;
  appearance: none;
}

.registerButton {
  @include cursor('white', pointer);

  height: 50px;
  margin-top: 40px;
  padding: 0 20px;
  border: none;
  border-radius: 10px;
  background-color: $primary-color;
  color: #fff;
  outline: none !important;
  transition: transform 0.1s;

  &:disabled {
    @include cursor('default', pointer);

    color: rgba(255, 255, 255, 0.9);
  }
}

.infoText {
  width: 380px;
  max-width: 80vw;
  margin-top: 10px;
  margin-bottom: -10px;
  color: #999;
  font-size: 12px;
  text-align: left;
}

.successText {
  margin-top: 20px;
}
