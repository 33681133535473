@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.heading {
  @include screenHeading();

  color: #444;
}

.mainContainer {
  position: relative;
  height: fit-content;
  min-height: 100vh;
  padding-bottom: 90px;
}

.topTriangles,
.bottomTriangles {
  position: absolute;
  width: 30vw;
  max-width: 500px;
}

.topTriangles {
  top: 20px;
  right: 20px;
}

.bottomTriangles {
  bottom: 20px;
  left: 20px;
  transform: rotate(180deg);
}

.buttonsContainer {
  @include flex();

  width: 100%;
  padding-top: 120px;

  button {
    @include cursor('default', pointer);

    margin: 0 10px;
    padding: 5px 30px;
    border: none !important;
    border-radius: 20px;
    background: #efefef;
    box-shadow: none !important;
    outline: none !important;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.04);
    }
  }

  .activeButton {
    background: $primary-color;
    color: #fff;

    &:hover {
      transform: scale(1);
    }
  }
}

.eventsContainer {
  @include flex(center, initial);

  flex-wrap: wrap;
  width: 100vw;
  padding: 40px 10vw;
}

.eventCard {
  width: 330px;
  position: relative;
  max-width: 90vw;
  height: 230px;
  margin: 20px;
  border-radius: 10px;
  transition: all 0.3s;
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #eee;
  overflow: visible;
  margin-top: 80px;

  div:first-child {
    width: 100%;
    text-align: center;
  }

  &:hover {
    div:first-child .eventIcon {
      transform: scale(1.1);
    }
  }

  p {
    margin-bottom: 5px;
    color: #333;
  }

  div:last-child {
    margin-top: -20px;
    padding: 55px 15px 0;
    border-radius: 10px;
    background: #fff;

    div {
      margin-top: 15px !important;
      padding: 0 !important;
    }
  }

  .timeIcon,
  .venueIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .timeIcon {
    height: 18px;
  }
}

.detailsModal {
  @include flex();

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  visibility: hidden;
}

.eventIcon {
  height: 110px;
  margin-top: -70px;
  transition: all 0.3s;
}

.pastIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 30px;
  opacity: 0.7;
}

.detailsContent {
  @include flex(center, center, column);

  position: fixed;
  top: 0;
  bottom: 0;
  width: 500px;
  max-width: 90vw;
  height: fit-content;
  max-height: 90vh;
  margin: auto;
  padding: 30px;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  transform: scale(0);

  .closeButton {
    @include cursor();

    width: 150px;
    margin-top: 20px;
    padding: 7px 0;
    border: none !important;
    border-radius: 10px;
    background: #d0566b;
    color: #fff !important;
    box-shadow: 2 2 4px 0 rgba(0, 0, 0, 0.2);
    outline: none !important;
    font-size: 20px;
    font-family: $secondary-font;
    text-align: center;
    text-decoration: none;
    pointer-events: all;
  }
}

@media (max-width: 576px) {
  .buttonsContainer {
    flex-direction: column;

    button {
      margin-bottom: 10px;
    }
  }

  .eventsContainer {
    padding: 40px 0;

    p {
      font-size: 14px;
    }
  }

  .eventIcon {
    height: 80px;
    margin-top: -40px;
  }

  .pastIcon {
    height: 20px;
  }

  .detailsContent {
    padding: 10px;
    font-size: 14px;

    .starIcon {
      height: 40px;
    }

    .closeButton {
      transform: scale(0.8);
    }
  }
}
