@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.mainContainer {
  @include shopMainContainer();

  font-weight: 500 !important;
}

.mainHeading {
  @include shopMainHeading();
}

.shopLink {
  @include capsLink();
}

.cartUpdatedText {
  height: 40px;
  color: #c36161;
}

.itemsTitle {
  margin-bottom: 0;
  font-size: 14px;
  letter-spacing: 3px;
  text-align: left;
}

.cartItemsContainer {
  width: 70vw;
  padding: 10px 0 70px;

  .cartItem {
    @include flex(space-between, flex-start);

    width: 100%;
    padding: 10px 0;

    img {
      object-fit: cover;
      width: 90px;
      height: 90px;
      border-radius: 4px;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05);
      object-position: center;
    }
  }

  .productTextContainer {
    margin-left: 20px;
    text-align: left;

    .productInfo {
      margin: 0;
      letter-spacing: 1px;
    }

    .salePrice {
      color: #cf9410 !important;
      font-weight: 500;

      span {
        color: #888 !important;
        font-weight: 400;
        text-decoration: line-through;
      }
    }

    .price,
    .salePrice {
      margin: 0;
      color: #888;
      font-size: 12px;
    }
  }
}

.line {
  width: 100%;
  margin: 0;
  color: #999;
}

.removeCartItem {
  @include cursor();

  border: none !important;
  background: none !important;
  color: rgb(195, 97, 97);
  box-shadow: none !important;
  outline: none !important;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
}

.proceedBtn {
  @include cursor('default', pointer);

  margin: 50px 0;
  padding: 7px 30px;
  border: none !important;
  border-radius: 15px;
  background-color: rgb(222, 131, 131);
  color: #fff;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1);
  outline: none !important;

  &enabled:hover {
    transform: scale(1.04);
  }
}

.chargesContainer {
  @include flex(flex-end, flex-end, column);

  width: 70vw;
  text-align: right;

  p {
    margin-bottom: 5px !important;
  }

  .line {
    width: 230px;
    margin-bottom: 10px;
  }

  p:nth-child(1) {
    margin-bottom: 5px;
  }

  .chargesPrice {
    width: 80px;
    margin-left: 30px;
  }

  .totalPrice {
    color: rgba(0, 0, 0, 0.7);
  }
}

.quantityContainer {
  @include flex(initial, center);

  width: 100%;
  margin-top: 10px;
  font-size: 16px !important;
  text-align: left;

  .quantityText {
    width: 50px;
    text-align: center !important;
  }

  .quantityCounter {
    @include flex(center, initial, row);

    width: 35px;
    height: 35px;
    padding-top: 2px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.07);
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.01);
    font-size: 20px;

    &:hover {
      color: $primary-color;
    }

    &:disabled {
      color: black !important;
    }
  }
}

@media (max-width: 768px) {
  .chargesContainer,
  .cartItemsContainer {
    width: 80vw;
  }
}

@media (max-width: 576px) {
  .chargesContainer,
  .proceedBtn {
    font-size: 16px;
  }

  .cartUpdatedText {
    font-size: 14px;
  }

  .chargesContainer,
  .cartItemsContainer {
    width: 90vw;
    color: #444;
  }

  .quantityContainer {
    p {
      font-size: 14px !important;
    }

    .quantityText {
      width: 40px;
    }

    .quantityCounter {
      width: 30px;
      height: 30px;
      padding-top: 5px;
    }
  }

  .cartItemsContainer .cartItem {
    img {
      width: 50px;
      height: 50px;
    }

    p {
      font-size: 14px;
    }
  }
}
