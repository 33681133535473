@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.heroSectionContainer {
  @include flex(center, center, column);

  width: 100%;
  height: 500px;
  min-height: 600px;
  margin-top: 40px;

  h1 {
    font-weight: 500 !important;
  }
}

.landingSprinkles {
  position: absolute;
  top: 0;
}

.welcomeText {
  @include flex(center, initial, column);

  padding-left: 10vw;

  svg {
    height: 60vw !important;
  }

  h1,
  h5 {
    width: 100%;
    font-weight: normal;
    text-align: left;

    span {
      color: $primary-color;
    }
  }
}

@media (max-width: 992px) {
  .heroSectionContainer {
    height: fit-content;
    min-height: 500px;
  }

  .contentContainer {
    flex-direction: column-reverse;

    h1,
    h5 {
      width: 100%;
      text-align: center;
    }

    div {
      width: 100vw !important;
      padding: 0 20px;
    }

    h1 {
      margin-top: 40px;
    }
  }
}

@media (max-width: 576px) {
  .contentContainer {
    h5 {
      font-size: 16px !important;
    }

    svg {
      transform: scale(1.4);
    }
  }
}
