@import '../../../../styles/mixins.scss';
@import '../../../../styles/variables.scss';

.mainContainer {
  @include gameMainContainer();

  min-height: 900px;
}

.heading {
  @include screenHeading();
}

.gameCardsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 50vw;
  min-height: 650px;
  padding: 100px 0 30px;
}

.scoreText {
  @include gameScoreText();
}

.gameCardContainer {
  @include flex();

  width: 23vw;
  max-width: 170px;
  height: 23vw;
  max-height: 170px;
  padding: 10px;
  background-color: transparent;
  pointer-events: none;
  user-select: none;
  perspective: 1000px;
}

.cardContent {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  pointer-events: all;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.flippedCard {
  transform: rotateY(180deg);
}

.cardFront,
.cardBack {
  @include flex();

  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10%;
  background-color: #fff;
  pointer-events: none;
  backface-visibility: hidden;

  img {
    width: 70%;
  }
}

.cardBack {
  transform: rotateY(180deg);
}

@media (min-width: 1360px) {
  .gameCardsContainer {
    max-width: 800px;
  }
}

@media (max-width: 1360px) {
  .gameCardsContainer {
    width: 60vw;
  }

  .gameCardContainer {
    width: 20vw;
    max-width: 170px;
    height: 20vw;
    max-height: 170px;
  }
}

@media (max-width: 1200px) {
  .gameCardsContainer {
    width: 70vw;
  }
}

@media (max-width: 992px) {
  .gameCardsContainer {
    width: 80vw;
  }

  .gameCardContainer {
    width: 20vw;
    height: 20vw;
  }
}

@media (max-width: 768px) {
  .mainContainer {
    justify-content: flex-start !important;
  }

  .gameCardsContainer {
    width: 80vw;
    min-height: fit-content;
    padding: 20px 0 30px;
  }

  .gameCardContainer {
    width: 18vw;
    height: 18vw;
  }

  .scoreText {
    position: initial;
    margin-top: 80px;
  }
}

@media (max-width: 480px) {
  .gameCardContainer {
    width: 20vw;
    height: 20vw;

    img {
      width: 80%;
    }
  }

  .gameCardsContainer {
    padding: 80px 0 30px;
  }
}
