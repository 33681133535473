@import '../../../styles/mixins.scss';

.gameItem {
  @include gameItem();

  img {
    object-fit: cover;
    height: 95%;
  }
}
