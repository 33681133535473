@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.successContainer {
	@include flex();

	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100vw;
	height: 100vh;
	padding: 10px;
	background: rgba(0, 0, 0, 0.2);
	visibility: hidden;
}

.successContent {
	@include flex(center, center, column);

	position: fixed;
	top: 0;
	bottom: 0;
	overflow: hidden;
	width: 500px;
	max-width: 90vw;
	height: fit-content;
	margin: auto;
	padding: 60px 40px;
	border-radius: 30px;
	background: #fff;
	color: #d0566b;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	pointer-events: none;
	transform: scale(0);

	.lottieContainer {
		position: absolute;
		top: 0;
		transform: scale(1.7);
	}

	h1,
	h4 {
		width: 80%;
		font-family: $secondary-font;
		letter-spacing: 2px;
		text-align: center;
	}

	a,
	button {
		@include cursor();

		width: 150px;
		margin-top: 50px;
		padding: 7px 0;
		border: none !important;
		border-radius: 10px;
		background: #d0566b;
		color: #fff !important;
		box-shadow: 2 2 4px 0 rgba(0, 0, 0, 0.2);
		outline: none !important;
		font-size: 20px;
		font-family: $secondary-font;
		text-align: center;
		text-decoration: none;
		pointer-events: all;
	}

	a:last-child {
		margin-top: 20px;
		background: #dc914d !important;
	}
}
