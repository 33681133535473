@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.mainContainer {
  @include shopMainContainer();

  align-items: center;
  text-align: center;
}

.text span {
  font-weight: 500;
}

.accountInfo {
  max-width: 100vw !important;
  margin: 2px !important;
  font-weight: 600;
  text-align: center !important;
}

.chuchuContainer {
  position: relative;
  z-index: 10;
  justify-self: center;
  width: 200px;
  height: 170px;
  margin: 90px auto 0;

  .lottieContainer {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 80px;
  }
}

.chuchu {
  height: 150px;
  margin-right: 40px;
}

.line {
  width: 300px;
  max-width: 90vw;
  margin: 0 auto;
  margin-bottom: 0;
  color: #999;
}

.orderInfoContainer {
  display: flex;
  flex-direction: column;
  width: 75vw;

  h3 {
    position: relative;
    background-color: rgb(249, 249, 249);
  }

  a {
    @include cursor('default', pointer);

    align-self: center;
    margin: 5px 0;
    padding: 7px 30px;
    border: none !important;
    border-radius: 15px;
    background-color: rgb(222, 131, 131);
    color: #fff;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1);
    outline: none !important;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      transform: scale(1.04);
    }

    &:last-child {
      background-color: #e8a91b;
    }
  }
}

.cartLink {
  @include capsLink();
}

@media (max-width: 992px) {
  .orderInfoContainer {
    width: 90vw;
  }
}

@media (max-width: 768px) {
  .orderInfoContainer {
    width: 100%;
  }

  .accountDetails {
    p {
      width: 135px !important;
      font-size: 15px;
    }
  }

  .mainContainer {
    padding: 40px 20px !important;
  }
}
