@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.mainContainer {
  @include flex(center, center, row);

  position: relative;
  width: 100vw;
  height: fit-content;
  min-height: 90vh;
  background-color: rgba(0, 0, 0, 0.02);
  color: rgb(124, 124, 124);
  text-align: center;
}

.listItem {
  color: #555;
}

.shopLink {
  @include capsLink();
}

.cartLink {
  @include capsLink('right');
}

.contentContainer {
  @include flex(center, initial);

  width: 100%;
  min-height: 90vh;
  padding: 110px 60px;
  margin-top: 20px;
}

.activeThumb {
  filter: grayscale(0%);
  transition: all 0.3s;
}

.inactiveThumb {
  filter: grayscale(100%);
  transition: all 0.3s;
}

.carouselContainer img {
  object-fit: contain;
  width: 100%;
  border-radius: 5px;
  user-select: none;
  object-position: center;
}

.carouselContainer {
  @include flex();

  position: relative;
  flex-direction: column;
  overflow: visible;
  width: 30vw;
  height: 30vw;
  margin-right: 30px;
  border-radius: 2px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.03);

  .thumbnailsContainer {
    position: absolute;
    top: 0;
    left: -70px;
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 55px;
    height: fit-content;
    max-height: 100%;
    padding: 10px 0;
    scrollbar-width: thin;

    img {
      width: 100%;
      height: 50px;
      margin: 0 0 5px;
      border-radius: 5px;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.03);
    }
  }

  div {
    height: 100%;
  }

  div ol li {
    width: 10px;
    height: 10px;
    margin: 0 8px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  div a {
    @include cursor('default', auto);

    top: 0;
    bottom: 0;
    z-index: 10;
    width: 40px;
    height: 20px;
    margin: auto;
    color: rgba(0, 0, 0, 0.6) !important;
    transition: all 0.2;

    &:hover {
      color: rgba(0, 0, 0, 0.6);
      transform: scale(1.3);
    }
  }

  .carouselIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    padding-top: 2px;
    border-radius: 50%;
    background: #fff;
    font-size: 12px;
    opacity: 1;
    text-decoration-color: transparent !important;
  }

  .carouselItem {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.infoContainer {
  @include flex(initial, initial, column);

  position: relative;
  width: 45vw;
  padding: 10px 20px 40px 20px;
  color: #555;
  font-size: 18px;
  font-weight: 500;
  background: #fff;
  border-radius: 10px;
  height: fit-content;
  box-shadow: 0 0 2px 2px #eee;
  letter-spacing: 1px;

  .stickerLink {
    width: fit-content !important;
    margin: 8px 5px 8px 0 !important;
    padding: 2px 10px;
    border-radius: 10px;
    background: $primary-color;
    color: #fff !important;
    font-weight: 500;
    font-size: 12px;
  }

  p,
  .stickerLink,
  h3 {
    margin: 0;
    color: #555;
    text-align: left;
    text-decoration: none;
  }

  .smallText {
    font-size: 14px !important;
  }

  .stickerLink,
  h3 {
    width: fit-content;
    font-weight: 600;
  }

  h3 {
    font-size: 34px;
  }

  .price,
  .salePrice {
    margin: 10px 0 15px 0;
    font-weight: 600;
    font-size: 20px;
  }

  .salePrice {
    color: $primary-color;

    span {
      margin-left: 15px;
      color: #999;
      text-decoration: line-through;
    }
  }
}

.quantityContainer {
  @include flex(initial, center);

  width: 100%;
  margin-top: 30px;
  text-align: left;

  .quantityText {
    width: 30px;
    text-align: center !important;
  }

  .quantityCounter {
    @include flex(center, initial, row);

    width: 45px;
    height: 45px;
    margin: 0 15px !important;
    padding-top: 6px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.07);
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.01);
    font-size: 20px;

    &:hover:not(:disabled) {
      color: $primary-color;
    }
  }

  button {
    @include cursor();

    border: none !important;
    outline: none !important;
  }
}

.addToCartBtn {
  @include cursor('default', pointer);

  width: 200px;
  margin: 40px auto 0 auto;
  padding: 8px 20px;
  border: none !important;
  border-radius: 2px;
  background-color: #d55b5b;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  box-shadow: 5px 5px 0 0 rgba(156, 108, 108, 0.3);
  outline: none !important;

  &:disabled {
    border: 1px solid #ccc !important;
    color: #999;
  }

  &:enabled:hover {
    transform: scale(1.01);
  }
}

.outofStockText {
  margin-top: 30px !important;
  color: #999 !important;
  font-size: 14px;
}

@media (max-width: 768px) {
  .mainContainer {
    height: fit-content;
  }

  .contentContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    padding: 90px 30px;
  }

  .carouselContainer {
    width: 600px;
    max-width: 50vw;
    height: 600px;
    max-height: 50vw;
    margin-right: 0;

    .thumbnailsContainer {
      top: 0;
      left: -60px;
      width: 50px;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  .infoContainer {
    width: 90vw;
    margin-top: 30px;
    padding: 20px 20px 60px 20px;
    font-size: 16px;

    p,
    .stickerLink,
    h3 {
      width: 100%;
      text-align: center;
    }

    .price,
    .salePrice {
      margin: 9px 0 20px;
      font-size: 20px;
    }

    .smallText {
      font-size: 14px;
    }
  }

  .quantityContainer {
    @include flex(center, center);

    margin-top: 15px;

    p {
      width: fit-content;
    }

    .quantityText {
      width: 30px;
      text-align: center !important;
    }

    .quantityCounter {
      @include flex(center, initial, row);

      width: 35px;
      height: 35px;
      margin: 0 10px !important;
      padding-top: 4px;
      font-size: 17px;
    }
  }

  .addToCartBtn {
    align-self: center;
  }
}

@media (max-width: 576px) {
  .contentContainer {
    padding: 70px 10px;
  }

  .carouselContainer {
    max-width: calc(100vw - 100px);
    max-height: calc(100vw - 100px);
    margin-bottom: 10px;
    margin-left: 50px;

    .thumbnailsContainer {
      top: 0;
      left: -60px;
      width: 50px;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  .infoContainer {
    width: 90vw;
    margin-top: 10px;
    font-size: 17px;
    letter-spacing: 0;

    h3 {
      font-size: 32px;
    }
  }

  ol {
    margin-top: 10px;
  }

  .quantityContainer {
    @include flex(center, center);

    margin-top: 15px;

    p {
      width: fit-content;
    }

    .quantityCounter {
      width: 30px;
      height: 30px;
      margin: 0 5px !important;
      padding-top: 2px;
    }
  }
}
