@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.alertContainer {
  @include flex();

  position: fixed;
  top: 5px;
  right: 0;
  left: 0;
  z-index: 100;
  width: fit-content;
  max-width: 90vw;
  margin: auto;
  padding: 7px 20px;
  border-radius: 5px;
  color: #fff;
  opacity: 0;
}

.success {
  background: #69c3aa;
}

.warning {
  background: #767676;
}

.error {
  background: #e58080;
}
