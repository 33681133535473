@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.mainContainer {
	@include flex(center, center, column);
	@include primaryScreen();

	position: relative;
	width: 100vw;
	height: fit-content !important;
	margin: 0;
	text-align: center;
}

.dashboardLinkContainer {
	@include flex(flex-end, flex-end);

	position: sticky;
	bottom: 10px;
	overflow: hidden;
	width: 100vw;
	margin-top: -100px;

	a {
		@include cursor('white', auto);

		position: absolute;
		right: 25px;
		bottom: 15px;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		background-color: transparent;
	}
}

.dashboardLink {
	@include flex();

	overflow: hidden;
	width: 110px;
	height: 110px;
	margin-right: 10px;
}
