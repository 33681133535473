@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.mainContainer {
  @include flex(center, center, column);

  position: relative;
  overflow: hidden;
  width: 100%;
  height: 700px;
  min-height: 600px;
  color: #333;

  h2 {
    font-weight: 500 !important;
  }

  h4 {
    font-weight: 400 !important;
    line-height: 1.5;
  }

  .trianlgesLineTop,
  .trianlgesLineBottom {
    position: absolute;
    height: 30px;
  }

  .trianlgesLineTop {
    top: 30px;
    right: 50%;
    transform: scaleY(-1);
  }

  .yellowText {
    padding: 0 8px;
    color: $primary-color;
  }

  .trianlgesLineBottom {
    bottom: 30px;
    left: 50%;
  }

  .textContainer {
    @include flex(center, center, column);

    width: 100%;
    padding: 0 20vw;
  }
}

@media (max-width: 768px) {
  .mainContainer {
    p {
      font-size: 14px !important;
    }

    h2 {
      font-size: 22px !important;
    }

    h4 {
      font-size: 16px !important;
    }

    .trianlgesLineTop,
    .trianlgesLineBottom {
      height: 20px;
    }

    .textContainer {
      padding: 0 50px;
    }
  }
}
