@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.mainContainer {
  --cursorX: 50vw;
  --cursorY: 50vh;

  @include flex(center, center, column);

  position: relative;
  z-index: 10000;
  height: 100vh;
  background: rgba(255, 255, 255, 0);
  cursor: none;
}

.homeIcon {
  top: 50px;
  left: 50px;
  z-index: 9999999;
}

.chuchu {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 300px;
  height: 300px;
  margin: auto;
}

.mainContainer::before {
  content: '';
  position: fixed;
  z-index: 10000;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle 10vmax at var(--cursorX) var(--cursorY),
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.85) 80%,
    rgba(0, 0, 0, 0.85) 100%
  );
  pointer-events: none;
}

.yellow {
  color: $primary-color;
}

.text {
  position: absolute;
  top: 50px;
  z-index: 100001;
  color: #fff;
  text-align: center;
}

.glasses {
  position: absolute;
  top: 60%;
  right: 19%;
  z-index: 0;
  width: 200px;
  height: 200px;
  pointer-events: all;
}

.pages {
  z-index: 1;
  height: 100px;
  margin-bottom: 180px;
  pointer-events: none;
}

.hiddenText {
  opacity: 0;
}

@media (max-width: 786px) {
  .mainContainer {
    overflow: hidden;
    height: 100vh;
  }

  .text {
    top: 120px;
    width: 90vw;
  }
}
