@import './variables.scss';

@mixin cursor($variant: 'default', $type: auto) {
  @if $variant == 'default' {
    cursor: url('../assets/images/default-cursor.png'), $type !important;
  } @else if $variant == 'white' {
    cursor: url('../assets/images/white-cursor.png'), $type !important;
  }
}

@mixin flex($justify: center, $align: center, $direction: row) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

@mixin square($height) {
  width: $height;
  height: $height;
}

@mixin primaryScreen() {
  height: 100vh;
  min-height: 800px;

  @media (max-width: 768px) {
    height: 100vh;
    min-height: 800px;
  }
}

@mixin screenHeading() {
  position: absolute;
  top: 25px;
  left: 110px;
  width: calc(100vw - 110px);
  color: #fff;
  font-weight: bold;
  font-size: 38px;
  text-shadow: 3px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;

  @media (max-width: 768px) {
    left: 80px;
    font-size: 32px;
  }

  @media (max-width: 576px) {
    top: 30px;
    font-size: 26px;
  }

  @media (max-width: 480px) {
    top: 32px;
    left: 80px;
    font-weight: 500 !important;
    font-size: 22px;
  }
}

@mixin swiperContainer() {
  @include flex();

  width: 100vw;

  & div:nth-child(1),
  & div:nth-child(2) {
    z-index: 10;
    color: #eee;
  }

  & div:nth-child(3) {
    width: 90vw;
  }
}

@mixin gameItem() {
  @include cursor('default', pointer);
  @include flex();

  width: 250px;
  height: 250px;
  padding: 5px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 10px 10px 0 0 rgba(0, 0, 0, 0.2);
  list-style-type: none;
  transition: all 0.2s;

  @media (max-width: 768px) {
    width: 140px;
    height: 140px;
    box-shadow: 8px 8px 0 0 rgba(0, 0, 0, 0.2);
  }

  img {
    object-fit: cover;
    width: 60%;
    border-radius: 10px;
    pointer-events: none;
    user-select: none;
  }

  &:hover {
    border-radius: 15px;
    transform: scale(1.03);
  }
}

@mixin gameMainContainer() {
  @include cursor('white', default);
  @include primaryScreen();
  @include flex(center, center, column);

  position: relative;
  padding: 15px;
  background: linear-gradient(
    -45deg,
    #f9cf43,
    #ffafbd,
    #ee9ca7,
    #b993d6,
    #ee9ca7,
    #ffb75e
  );
  background-size: 600% 600%;
  animation: gradient 90s ease infinite;
}

@mixin gameScoreText() {
  position: absolute;
  top: 30px;
  right: 50px;

  p {
    margin-bottom: 0;
    padding-left: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 26px;
    font-family: $secondary-font;
    letter-spacing: 3px;
    text-shadow: 3px 3px rgba(0, 0, 0, 0.1);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

@mixin capsLink($placement: 'left') {
  @include cursor();

  @if $placement == 'right' {
    right: 30px;
  } @else {
    left: 30px;
  }

  position: absolute;
  top: 31px;
  z-index: 1;
  color: #555;
  font-weight: 600;
  letter-spacing: 3px;
  text-decoration: none;

  @media (max-width: 576px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    @if $placement == 'right' {
      right: 18px;

      span {
        color: #888;
        font-size: 12px !important;
      }
    } @else {
      left: 18px;
    }

    top: 38px;
    font-size: 16px !important;

    span {
      font-size: 16px !important;
    }
  }

  span {
    width: 10px;
    height: 20px;
    margin: 0 3px 0 0 !important;
    font-weight: 600;
  }

  &:hover {
    color: rgb(234, 147, 147);
    transform: scale(1.03);
  }
}

@mixin shopMainHeading() {
  width: 100vw;
  margin-top: -10px;
  padding: 0 100px;
  color: #555;
  font-weight: 400;
  letter-spacing: 3px;
  text-align: center;

  @media (max-width: 576px) {
    margin-top: -8px;
    padding: 0 60px;
    font-size: 18px;
    letter-spacing: 1px;
  }

  @media (max-width: 480px) {
    margin-top: 0px;
    font-weight: 500;
    font-size: 17px;
  }
}

@mixin shopMainContainer() {
  @include cursor('default', default);
  @include flex(initial, center, column);

  width: 100vw;
  height: fit-content;
  min-height: 100vh;
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.02);
  text-align: center;
}
