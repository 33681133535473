@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.mainContainer {
  @include primaryScreen();
  @include flex(initial, center, column);

  width: 100vw;
  background: url('../../assets/images/triangles.svg');
  background-position: top;
  background-size: 27%;
}

.banner {
  width: 45%;
  max-width: 1000px;
  margin: 30px 0 50px;
}

.topDrawing {
  @include square(20vw);

  width: 400px;
  max-width: 80vw;
  height: 400px;
  max-height: 80vw;
  margin: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 6px 11px 0 rgba(0, 0, 0, 0.24);

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center;
  }
}

@media (max-width: 768px) {
  .mainContainer {
    padding: 80px 20px;
  }

  .banner {
    width: 90%;
    margin: 0 0 100px;
  }
}
