@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.mainContainer {
	@include cursor('white', default);
	@include primaryScreen();
	@include flex();

	position: relative;
	height: fit-content !important;
	padding: 70px 10px;
	background: linear-gradient(#f9cf43, #f4bc3e);
}

.heading {
	@include screenHeading();
}

@keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

.allGamesContainer {
	display: grid;
	grid-template-rows: repeat(auto-fit, minmax(260px, 260px));
	grid-template-columns: repeat(auto-fit, minmax(260px, 260px));
	grid-gap: 16px;
	justify-content: center;
	width: calc(100vw - 20px);
	min-height: 75vh;
	padding: initial;
	padding-top: 50px;
}

@media (max-width: 768px) {
	.allGamesContainer {
		grid-template-rows: repeat(auto-fit, minmax(150px, 150px));
		grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
		grid-gap: 10px;
		width: calc(100vw - 10px);
		min-height: 80vh;
		padding: 50px 0;
	}
}
